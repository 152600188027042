// Temp rules.
// This file should be changed to .css
@import "../../styles/vars.scss";

.translatePlaceholder {
  position: absolute;
  top: 8px;
  color: #333;

  // if you want the 3 dots to dissapear, remove this
  padding-left: 25px;
  max-width: 100%;

  overflow: hidden;
  &:has(input) {
    white-space: nowrap;
  }
  text-overflow: ellipsis;

  // end remove, good 1! :)

  &:has(> span:not(:empty)) {
    & ~ input,
    & ~ textarea {
      &,
      &::placeholder {
        color: transparent !important;
      }
    }
  }
}

.InpContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &.error .inp {
    background: rgba(red, 0.35) !important;

    svg path {
      fill: rgba(red, 0.65) !important;
    }
  }
  &.success .inp {
    svg path {
      fill: rgba(blue, 0.5) !important;
    }
  }
}
.InpContainer > label {
  width: 350px;
  text-align: center;
  font-size: 14px;
  margin: 2.5px;
}

.inp {
  position: relative;
  height: 35px;
  width: 350px;
  max-width: 100%;
  padding: 0 10px;

  &.file {
    height: auto !important;
    padding-block: 10px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;

  cursor: text;

  // Temp rules.
  color: var(--primary);
  background: rgba(black, 0.1);
  box-shadow: $shadow-0-0-3;

  * {
    transition: 0.5s;
  }
}
.inp .FilesLabel {
  font-size: 12px;
}
.inp input {
  font-size: 16px;
  font-weight: 300;
  height: 100%;
  width: 100%;

  background-color: transparent;
  border: none;
  outline: none;

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #edd4d4 inset !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover {
    box-shadow: 0 0 0px 1000px #e5e5e5 inset !important;
  }
}

.inp input[placeholder] {
  text-overflow: ellipsis;
}

.inp input[type="file"] {
  display: none;
}
.textarea {
  height: auto !important;

  &.large {
    height: 170px !important;
  }
  textarea {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    min-height: 150px;
    height: 95%;
    background: transparent;
    border: unset;
    resize: none;
    outline: none;
    color: black;
  }
}
.inp:focus-within {
  box-shadow: 0 0 1px var(--primary);

  svg path {
    fill: var(--primary);
  }
}

.input.big {
  width: 600px;
  height: 60px;
  font-size: 22px;
}
.inp.large {
  width: 420px;
  height: 40px;
  font-size: 18px;
}
.inp.small {
  width: 320px;
  height: 30px;
  font-size: 14px;
}

.Icon {
  display: grid;
  place-items: center;

  svg {
    width: 20px;

    path {
      fill: rgba(black, 0.5);
    }
  }
}
.Helper {
  font-size: 12px;
  margin-top: 2.5px;
}
.error .Helper {
  color: rgba(red, 0.75);
}
.success .Helper {
  color: rgba(blue, 0.75);
}

// SELECT
.DropDown {
  z-index: 50;
  position: absolute;
  top: calc(100% + 15px);
  width: 100%;
  padding: 5px;

  font-size: 14px;

  background: #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(var(--primary), 0.2);

  height: 150px;
  overflow-y: auto;

  div {
    min-height: 35px;
    line-height: 35px;
    padding: 0 10px;
    border-radius: 5px;
    display: flex;

    cursor: pointer;

    &.Active,
    &:hover {
      background: var(--primary);
    }
  }
}
.comment {
  color: red;
}
